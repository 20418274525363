import SHA256 from './sha256.js'

const button = document.querySelector(".login__btn")
const passwordInput = document.querySelector(".login__password")
const eyeIcon = document.querySelector(".login__eye")
const loading = document.querySelector(".loading-page")
const imgBtnLoading = document.querySelector(".login__btn-loading")
const textBtn = document.querySelector(".login__btn-text")
const loginWrong = document.querySelector(".login__wrong")

const HASHED_PASS = "912df38fe384db9fe00ce18570a569561a76a48acfaaffa01a859df4a9bcb66a"


button.addEventListener("click", () => {
    localStorage.setItem("password", SHA256(passwordInput.value))
    renderUiBeforeAuth()
    auth(renderUiAfterAuth)
})

passwordInput.addEventListener("keydown", e => {
    if (e.keyCode === 13) {
        localStorage.setItem("password", SHA256(passwordInput.value))
        renderUiBeforeAuth()
        auth(renderUiAfterAuth)
    }
})

eyeIcon.addEventListener("click", () => {
    if (eyeIcon.classList.contains("icon-eye-blocked")) {
        eyeIcon.classList.remove("icon-eye-blocked")
        eyeIcon.classList.add("icon-eye")
        passwordInput.type = "text"
    } else {
        eyeIcon.classList.remove("icon-eye")
        eyeIcon.classList.add("icon-eye-blocked")
        passwordInput.type = "password"
    }
})


function auth(callback) {
    setTimeout(() => {
        const authenticated = SHA256(passwordInput.value) === HASHED_PASS;
        callback(authenticated)
    }, 3000)
}

function renderUiBeforeAuth() {
    button.disabled = true
    textBtn.classList.add("login__btn-text--hidden")
    imgBtnLoading.classList.remove("login__btn-loading--hidden")
}

function renderUiAfterAuth(authenticated) {
    if (authenticated) {
        location.href = "/"
    } else {
        button.disabled = false
        textBtn.classList.remove("login__btn-text--hidden")
        imgBtnLoading.classList.add("login__btn-loading--hidden")
        loginWrong.classList.remove("login__wrong--hidden")
        loginWrong.textContent = loginWrong.textContent+"!"
    }
}


addEventListener("load", () => {
    setTimeout(() => loading.classList.add("loading-page--hide"), 3000)
})